import React, { useState } from "react";
import isUrl from 'is-url';

import bot from '../assets/bot.gif';
import Modal from '@mui/material/Modal';


function WaitingAnimation({ resource, modalState }) {
    if (modalState !== "WAITING_STATE") return null;
    var waitingAnimationMessage = "Your Doc-GPT is being created, it will be ready in a minute...";
    if (resource === "WEBSITE") {
        waitingAnimationMessage = "Your Website-GPT is being created, it will be ready in a minute...";
    }
    return (
        <>
            <img className="waitingAnimationGif" src={bot} alt="loading..." />
            <p className="waitingAnimationMessage">{waitingAnimationMessage}</p>
        </>
    )
}

const FileSelected = ({ resource, modalState, selectedResource, setConversationId, setModalState, setOpenModal }) => {
    if (modalState !== "FILE_SELECTED_STATE") {
        return null;
    }
    var buttonLabel = "Create your Doc-GPT";
    var instruction = "Now, we are ready to train a customized bot based on the document you uploaded.";
    if (resource === "WEBSITE") {
        buttonLabel = "Create your Website-GPT";
        instruction = "Now, we are ready to train a customized bot based on the website you entered.";
    }
    const handleSubmission = () => {
        setModalState("WAITING_STATE");
        const formData = new FormData();
        var url = `${process.env.REACT_APP_API_URL}/v1/upload-pdf/`;
        if (resource === "DOCUMENT") {
            formData.append('File', selectedResource);

        }
        else if (resource === "WEBSITE") {
            formData.append('website', selectedResource);
            url = `${process.env.REACT_APP_API_URL}/v1/upload-website/`;
        }
        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then(async (result) => {
                if(!result['id']){
                    alert("There was an error, please try again or contact support.");
                    setModalState("INITIAL_STATE");
                    return;
                }
                setConversationId(result['id']);
                setOpenModal(false);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
    return (
        <>
            <p className="instruction">{instruction}</p>
            <button onClick={handleSubmission} className="fileUploadButton">{buttonLabel}</button>
        </>
    )
}

const InputResource = ({ resource, setModalState, setSelectedResource }) => {
    const changeHandlerDocGpt = (event) => {
        const file = event.target.files[0];
        const fileSize = file.size / 1024;
        const MAX_FILE_SIZE = 1000;
        if (!file) {
            return;
        }
        if (fileSize > MAX_FILE_SIZE) {
            alert("File size should be less than 1MB");
            return;
        }
        setSelectedResource(file);
        setModalState("FILE_SELECTED_STATE");
    };

    const changeHandlerWebsiteGpt = (event) => {
        event.preventDefault();
        const url = document.getElementById("urlInput").value.trim();
        if (!isUrl(url)) {
            alert("Please enter a valid URL");
            return;
        }
        setSelectedResource(url);
        setModalState("FILE_SELECTED_STATE");
    }

    if (resource === "WEBSITE") {
        return (
            <>
                <div>
                    <input type="text" name="url" className="UrlUploadHelperText" id="urlInput" />
                </div>
                <div className="websiteUpload">
                    <button type="button" onClick={changeHandlerWebsiteGpt} className="fileUploadButton"> Submit </button>
                </div>
            </>

        )
    }

    return (
        <input type="file" name="file" onChange={changeHandlerDocGpt} accept="application/pdf" className="fileUploadHelperText" />
    )

}

const InitialState = ({ resource, modalState, setModalState, setSelectedResource }) => {
    if (modalState !== "INITIAL_STATE") {
        return null;
    }

    var instruction = "Upload a file to ask questions to the Doc-GPT about it.";
    var disclaimer = "For demo purposes, we support only PDF. The production version supports other file types.";
    if (resource === "WEBSITE") {
        instruction = "Enter a URL to ask questions to the Website-GPT about it.";
        disclaimer = "For demo purposes, we analyze only the top 10 popular pages. The production version can support all the website.";
    }
    return (
        <>
            <p className="instruction">{instruction}</p>
            <InputResource resource={resource} setModalState={setModalState} setSelectedResource={setSelectedResource} />
            <p className="disclaimer">{disclaimer}</p>
        </>
    )
}

const Menu = ({ resource, selectedResource, setSelectedResource, setConversationId }) => {
    const [modalState, setModalState] = useState("INITIAL_STATE");
    const [openModal, setOpenModal] = useState(true);
    var header = "Doc-GPT";
    if (resource === "WEBSITE") {
        header = "Website-GPT";
    }
    return (
        <Modal open={openModal} >
            <div className="modal">
                <div className="header"> Welcome to {header} Demo</div>
                <InitialState resource={resource} modalState={modalState} setModalState={setModalState} setSelectedResource={setSelectedResource} />
                <FileSelected resource={resource} modalState={modalState} selectedResource={selectedResource} setConversationId={setConversationId} setModalState={setModalState} setOpenModal={setOpenModal} />
                <WaitingAnimation resource={resource} modalState={modalState} />
            </div>
        </Modal>
    );
};

export default Menu;
