import React, { useState } from "react";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const PromptForm = ({ resource, chatLog, conversationId, setChatLog, setErr }) => {
    const [inputPrompt, setInputPrompt] = useState("");

    async function callAPI() {
        try {
            console.log(["conversationId", conversationId])
            var url = `${process.env.REACT_APP_API_URL}/v1/converse/`;
            if(resource === "WEBSITE"){
                url = `${process.env.REACT_APP_API_URL}/v1/converse-website/`;
            }
            const response = await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ message: inputPrompt, conversation_id: conversationId }),
            });
            const data = await response.json();
            setChatLog([
                ...chatLog,
                {
                    chatPrompt: inputPrompt,
                    botMessage: data.botResponse,
                },
            ]);
            setErr(false);
        } catch (err) {
            setChatLog([
                ...chatLog,
                {
                    chatPrompt: inputPrompt,
                    botMessage: "I am not able to respond now, please retry later.",
                },
            ]);
            console.log(err);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputPrompt.trim() === "") {
            return;
        }
        setChatLog([...chatLog, { chatPrompt: inputPrompt }]);
        // e.target.querySelector("textarea").blur();
        callAPI();
        setInputPrompt("");
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="inputPromptWrapper">
                <TextField
                    id=""
                    className="inputPrompttTextarea"
                    multiline
                    value={inputPrompt}
                    type="text"
                    onChange={(e) => setInputPrompt(e.target.value)}
                    inputProps={{ style: { color: "white" } }}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit(e);
                        }
                    }}
                    placeholder="Write your questions here"
                />
                <Button aria-label="form submit" type="submit" className="promptButton">
                    <svg
                        fill="#ADACBF"
                        width={15}
                        height={20}
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        stroke="#212023"
                        strokeWidth={0}
                    >
                        <title>{"submit form"}</title>
                        <path
                            d="m30.669 1.665-.014-.019a.73.73 0 0 0-.16-.21h-.001c-.013-.011-.032-.005-.046-.015-.02-.016-.028-.041-.05-.055a.713.713 0 0 0-.374-.106l-.05.002h.002a.628.628 0 0 0-.095.024l.005-.001a.76.76 0 0 0-.264.067l.005-.002-27.999 16a.753.753 0 0 0 .053 1.331l.005.002 9.564 4.414v6.904a.75.75 0 0 0 1.164.625l-.003.002 6.259-4.106 9.015 4.161c.092.043.2.068.314.068H28a.75.75 0 0 0 .747-.695v-.002l2-27.999c.001-.014-.008-.025-.008-.039l.001-.032a.739.739 0 0 0-.073-.322l.002.004zm-4.174 3.202-14.716 16.82-8.143-3.758zM12.75 28.611v-4.823l4.315 1.992zm14.58.254-8.32-3.841c-.024-.015-.038-.042-.064-.054l-5.722-2.656 15.87-18.139z"
                            stroke="none"
                        />
                    </svg>
                </Button>
            </div>
        </form>
    );
};

export default PromptForm;
