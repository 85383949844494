import MainGpt from "./Main";



const WebsiteGpt = () => {
    return (
        <MainGpt resource="WEBSITE" />
    );
};

export default WebsiteGpt;