import React from "react";
import BotResponse from "./BotResponse";
import UserPrompt from "./UserPrompt";
import IntroSection from "./IntroSection";

const Conversation = ({ resource, chatLog, chatLogRef, err, selectedResource }) => {

    return (
        <div className="chatLogWrapper">
            {chatLog.length > 0 ? (
                chatLog.map((chat, idx) => (
                    <div
                        className="chatLog"
                        key={idx}
                        id={`navPrompt-${chat.chatPrompt.replace(
                            /[^a-zA-Z0-9]/g,
                            "-"
                        )}`}
                    >
                        <UserPrompt chatPrompt={chat.chatPrompt} />
                        <BotResponse
                            response={chat.botMessage}
                            chatLogRef={chatLogRef}
                            chat={chat}
                            err={err}
                        />
                    </div>
                ))) : <IntroSection resource={resource} selectedResource={selectedResource} />
            }
            <div ref={chatLogRef} />
        </div>
    );
};

export default Conversation;
