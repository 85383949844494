import React, { useEffect, useState } from "react";

import Error from "../components/Error";
import Loading from "../components/Loading";
import SvgComponent from "../components/SvgComponent";
import Avatar from "../components/Avatar";

const BotResponse = ({ response, chatLogRef, chat, err }) => {
    const [botResoponse, setBotResponse] = useState("");

    useEffect(() => {
        if (!response) {
            return;
        }
        let index = 1;
        let msg = setInterval(() => {
            setBotResponse(response.slice(0, index));
            if (index >= response.length) {
                clearInterval(msg);
            }
            index++;
            chatLogRef?.current?.scrollIntoView({ behavior: "instant", block: "end", });
        }, 40);
        return () => clearInterval(msg); // clear interval on component unmount
    }, [chatLogRef, response]);

    return (
        <>
            <div className="botMessageMainContainer">
                <div className="botMessageWrapper">
                    <Avatar bg="white" className="customAiLogo">
                        <SvgComponent w={41} h={41} />
                    </Avatar>
                    {chat.botMessage ? (
                        <div id="botMessage">
                            <pre>
                                {botResoponse}
                                {botResoponse === response ? "" : "|"}
                            </pre>

                        </div>
                    ) : err ? (
                        <Error err={err} />
                    ) : (
                        <Loading />
                    )}
                </div>
            </div>

        </>
    );
};

export default BotResponse;
