import React, { useEffect, useRef, useState } from "react";

import Conversation from "../components/Conversation";
import PromptForm from "../components/PromptForm";
import Menu from "../components/Menu";





const MainGpt = ({resource}) => {
    const [chatLog, setChatLog] = useState([]);
    const [err, setErr] = useState(false);
    const chatLogRef = useRef(null);
    const [selectedResource, setSelectedResource] = useState();
    const [conversationId, setConversationId] = useState(null);

    useEffect(() => {
        if (chatLogRef.current) {
            chatLogRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        }
        return;
    }, [chatLog, chatLogRef]);

    return (
        <>
            <Menu resource={resource} selectedResource={selectedResource} setSelectedResource={setSelectedResource} setConversationId={setConversationId} />
            <section className="chatBox">
                <Conversation resource={resource}  chatLog={chatLog} chatLogRef={chatLogRef} err={err} selectedResource={selectedResource} />
                <PromptForm resource={resource} chatLog={chatLog} conversationId={conversationId} setChatLog={setChatLog} setErr={setErr} />
            </section>
        </>

    );
};

export default MainGpt;