import MainGpt from "./Main";



const DocGpt = () => {
    return (
        <MainGpt resource="DOCUMENT" />
    );
};

export default DocGpt;