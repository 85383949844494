import React from "react";

const IntroSection = ({ resource, selectedResource }) => {
  if (!selectedResource) return null;
  var h1Content = "Doc-GPT";
  var h2Content = "document";
  var resourceName = selectedResource.name;
  if (resource === "WEBSITE") {
    h1Content = "Website-GPT";
    h2Content = "website";
    resourceName = selectedResource;
  }
  return (
    <div id="introsection">
      <h1>
        Your {h1Content} is ready
      </h1>
      <h2>
        Your {h2Content} <i>{resourceName}</i> has been analyzed and the chatbot knows it in details.
      </h2>
      <p>
        Start asking questions using the chat box below.
      </p>
    </div>
  );
};

export default IntroSection;
