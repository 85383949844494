import React from "react";

import mainLogo from'../assets/logo.png';

const SvgComponent = ({ w, h, stroke }) => {
    return (
        <img
            width={w}
            height={h}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            strokeWidth={1.5}
            stroke={stroke}
            className="h-6 w-6"
            src={mainLogo}
            alt="avatar bot"
        />
    );
};

export default SvgComponent;
